import logo from './oasis.png';
import './App.css';
import React from 'react';
import { PaperSDKProvider, PaperCheckout }  from '@paperxyz/react-client-sdk';

function App() {
  return (
    <PaperSDKProvider
      chainName="Rinkeby"
      clientId="5f92edbc-b00b-405d-8c72-3793ac87db4a"
    >
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="title">
          Welcome To The Oasis
        </div>
        <PaperCheckout checkoutId='fc585f75-5cc7-44ed-bb5e-535661909242' display='DRAWER'
        options={{
          width: 400,
          height: 650,
          colorBackground: '#232323',
          colorPrimary: '#d1e9ff',
          colorText: '#ffffff',
          borderRadius: 6,
          fontFamily: 'Open Sans',
        }} />
      </header>
    </div>
    </PaperSDKProvider>
  );
}

export default App;
